





import { Component, Mixins, Ref, Vue, Watch } from 'vue-property-decorator'
import ModalRemoveAccount from '@/components/organisms/quiz/ModalRemoveAccount.vue'
import BaseQuiz from '@/mixins/quiz/BaseQuiz'

@Component({
  components: {
    ModalRemoveAccount,
  },
})
export default class ResetStudy extends Mixins(BaseQuiz) {
  @Ref()
  private modalResetRef!: ModalRemoveAccount

  private profile: any = { userId: '' }
  private async mounted() {
    this.profile = await this.getProfileLine()
    this.modalResetRef.show()
  }
}
