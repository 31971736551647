











import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import HeaderModal from '@/components/atoms/quiz/HeaderModal.vue'
import ModalBorder from '@/components/molecules/quiz/ModalBorder.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import DonutOverview from '@/components/organisms/quiz/DonutOverview.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import BaseQuiz from '@/mixins/quiz/BaseQuiz'

@Component({
  components: {
    HeaderModal,
    ModalBorder,
    DonutOverview,
    ButtonBase,
  },
})
export default class ModalRemoveAccount extends Mixins(ModalBaseMethod, BaseQuiz) {
  @Prop()
  lineId!: string

  private async removeUser() {
    Vue.prototype.$loading.start()
    await Vue.prototype.$http.httpWithToken.delete('/v3/history_quiz/leave_group').then(()=> {
      this.backOut()
    })
    Vue.prototype.$loading.complete()
  }

  private back(){
    this.$router.back()
  }
}
